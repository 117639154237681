<template>
	<div class="group-chat-rank">
		<vant-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="head">
				<div class="head-nav">
					<div :class="[{ color: module == 1 }]" @click="onChange(1)">当日排名</div>
					<div :class="[{ color: module == 2 }]" @click="onChange(2)">当月排名</div>
					<div :class="[{ color: module == 3 }]" @click="onChange(3)">年度排名</div>
				</div>
				<div class="head-top">
					<div class="head-item m-top" v-for="(item, index) in rankList" v-if="index == 1">
						<div class="head-sculpture sculpture"><img :src="item.avatar" alt="" /></div>
						<div class="head-item-picture picture">
							<img src="@/assets/img/icon208.png" alt="" />
						</div>
						<div class="head-item-title">{{ testuserName(item.name) }}</div>
						<div class="head-item-text">{{ item.total_love_value }}</div>
					</div>
					<div class="head-item" v-for="(vo, index) in rankList" v-if="index == 0">
						<div class="head-sculpture"><img :src="vo.avatar" alt="" /></div>
						<div class="head-item-picture">
							<img src="@/assets/img/icon207.png" alt="" />
						</div>
						<div class="head-item-title">{{ testuserName(vo.name) }}</div>
						<div class="head-item-text">{{ vo.total_love_value }}</div>
					</div>
					<div class="head-item m-top" v-for="(to, index) in rankList" v-if="index == 2">
						<div class="head-sculpture sculpture"><img :src="to.avatar" alt="" /></div>
						<div class="head-item-picture picture">
							<img src="@/assets/img/icon209.png" alt="" />
						</div>
						<div class="head-item-title">{{ testuserName(to.name) }}</div>
						<div class="head-item-text">{{ to.total_love_value }}</div>
					</div>
				</div>
				<div class="head-bottom">
					<div class="head-bottom-left">
						<div class="title">排序</div>
						<div class="text">姓名</div>
					</div>
					<div class="text">爱心值</div>
				</div>
				<div class="middle" v-for="(tp, index) in rankList" v-if="index > 2">
					<div class="middle-item">
						<div class="item-top">
							<div class="item-top-left">
								<div class="item-top-left-number">{{ index + 1 }}</div>
								<div class="item-top-left-picture">
									<img :src="tp.avatar" alt="" />
								</div>
								<div class="item-top-left-box">
									<div class="title">{{ testuserName(tp.name) }}</div>
									<div class="text">{{ tp.level_text }}</div>
								</div>
							</div>
							<div class="item-top-right">{{ tp.total_love_value }}</div>
						</div>
					</div>
				</div>
			</div>
		</vant-list>
	</div>
</template>

<script>
	import WTab from '@/components/Tab';
	import WList from '@/components/List';
	import messageApi from '@/api/message';
	import VantList from '@/components/VantList';

	export default {
		name: 'GroupChatRank',
		data() {
			return {
				module: 1,
				loading: true,
				finished: false,
				isEmpty: false,
				rankList: [],
				params: {
					page: 1,
					limit: 20
				}
			};
		},
		props: {
			groupId: {
				default: false
			},
			district_id: {
				default: ''
			}
		},
		created() {
			this.onLoad();
		},
		methods: {
			testuserName(value) {
				return value.replace(value.substr(1, 1), "*");
			},
			onLoad() {
				this.loading = true;

				messageApi.chatLoveRank({
					group_id: this.groupId,
					order: this.module,
					page: this.params.page,
					limit: this.params.limit,
					district_id: this.district_id
				}).then(result => {

					this.rankList = this.rankList.concat(result.data.list);
					
					this.params.page++;
					this.loading = false;
					
					if (this.rankList.length <= 0) {
						this.isEmpty = true;
					}
					
					if (this.rankList.length <= result.data.list.length) {
						this.finished = true;
					}

				}).catch(error => {
					console.log(error);
				});
			},
			onChange(module) {
				this.module = module;
				this.params.page = 1;
				this.finished = false;
				this.isEmpty = false;
				this.rankList = [];
				this.onLoad();
			}
		},
		components: {
			WTab,
			WList,
			VantList
		}
	};
</script>

<style lang="less" scoped>
	.group-chat-rank {
		background: #f2f2f2;

		.nav-top {
			padding: 9px 0 7px;
			text-align: center;
			background-color: #fff;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

			.nav-top-title {
				font-size: 18px;
				line-height: 24px;
				color: #333;
			}

			.nav-top-text {
				font-size: 12px;
				line-height: 16px;
				color: #aaa;
			}
		}

		.head-title {
			padding: 15px 10px 10px;
		}

		.head {
			background: #fff;
			box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
			border-radius: 15px 15px 0px 0px;
			padding: 20px 10px;
			margin-top: 12px;

			.head-nav {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 10px;
				border-bottom: 1px solid #ddd;

				div {
					font-size: 16px;
					line-height: 23px;
					color: #000;
					padding-bottom: 12px;
					margin-bottom: -1px;
				}
			}

			.color {
				font-weight: bold;
				color: #3377ff !important;
				border-bottom: 2px solid #3377ff;
			}

			.head-top {
				display: flex;
				justify-content: space-between;
				padding: 20px 10px 15px;

				.head-item {
					position: relative;
					text-align: center;

					.head-sculpture {
						width: 102px;
						min-width: 102px;
						height: 101px;
						margin-left: 50%;
						transform: translateX(-50%);

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
					}

					.sculpture {
						width: 72px;
						min-width: 72px;
						height: 72px;
					}

					.head-item-picture {
						width: 115px;
						min-width: 115px;
						height: 115px;
						position: absolute;
						top: -9px;
						margin-left: 50%;
						transform: translateX(-50%);

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.picture {
						width: 85px;
						min-width: 85px;
						height: 85px;
					}

					.head-item-title {
						width: 100px;
						min-width: 100px;
						font-weight: bold;
						font-size: 18px;
						line-height: 26px;
						color: #333;
						margin-top: 5px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.head-item-text {
						font-size: 14px;
						line-height: 20px;
						color: #666;
					}
				}

				.m-top {
					margin-top: 50px;
				}
			}

			.head-bottom {
				padding: 5px 10px;
				background: #f2f2f2;
				border-radius: 6px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.head-bottom-left {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.title {
						font-size: 16px;
						line-height: 26px;
						color: #666;
						margin-right: 44px;
					}
				}

				.text {
					font-size: 16px;
					line-height: 26px;
					color: #666;
				}
			}

			.middle {
				margin-top: 10px;
				padding: 0 10px;
				border-radius: 6px;
				background-color: #fff;
				border-bottom: 1px solid #f2f2f2;

				.middle-item {
					.item-top {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding-bottom: 10px;

						.item-top-left {
							display: flex;
							justify-content: flex-start;
							align-items: center;

							.item-top-left-number {
								font-weight: bold;
								font-size: 18px;
								line-height: 20px;
								color: #3377ff;
								margin-right: 15px;
							}

							.item-top-left-picture {
								width: 40px;
								min-width: 40px;
								height: 40px;
								margin-right: 10px;

								img {
									width: 100%;
									height: 100%;
									border-radius: 50%;
									object-fit: cover;
								}
							}

							.item-top-left-box {
								.title {
									font-size: 16px;
									line-height: 23px;
									color: #333;
								}

								.text {
									font-size: 14px;
									line-height: 21px;
									color: #666;
								}
							}
						}

						.item-top-right {
							font-size: 24px;
							line-height: 36px;
							color: #3377ff;
						}
					}
				}
			}
		}
	}
</style>